import React from "react"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
  TAAISearchUser,
  TAbanManyUsers,
  TAbanUser,
  TAChatGptSearchUser,
  TAfindAllUser,
  TAprioritizeUser,
  TAremoveVerification,
  TAupdateCategory,
  TAupdateSubCategory,
  TAverifyUser,
} from "../../../services/userAPI"
import { User } from "../../../types/UserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { FilterValue } from "../../../types/FilterData"
import DownloadCSVButton from "../../../components/DownloadCSVButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan, faSearch } from "@fortawesome/free-solid-svg-icons"
import { selectGetAllUserFilters } from "../../../redux/store/filters/user/getAllUserSlice"
import EditCategoryModal from "../../../components/EditCategoryModal"
import EditSubCategoryModal from "../../../components/EditSubCategoryModal"
import ProfileDetailModal from "../../../components/ProfileDetailModal"
import { handleSorting, numFixer } from "./components/Functions"
import { columnConfig } from "./components/columns"
import { generateColumn } from "../bannedUsers/components/columns"
import Filters from "./components/Filters"

const fetchData = async (
  page: number,
  perPage: number,
  query: any,
  sortBy: string,
  sortOrder: boolean,
  exportCSV: string,
) => {
  try {
    const response = await TAfindAllUser(page, perPage, query, sortBy, sortOrder, exportCSV)
    if (response && Array.isArray(response.users)) {
      const totalRecords = response.totalDocuments
      const data = response.users.map((item: any, index: any) => {
        return {
          id: index + 1,
          insta_fullname: item.insta.full_name,
          insta_username: item.insta.username,
          insta_followers: numFixer(item.insta.followers),
          insta_following: numFixer(item.insta.following),
          insta_average_like: numFixer(item.insta.average_like),
          insta_engagement_rate: numFixer(item.insta.engagement_rate),
          insta_average_reel_view: numFixer(item.insta.average_reel_view),
          insta_face_count: numFixer(item.insta.face_count),
          insta_post_number: numFixer(item.insta.post_number),
          tiktok_username: numFixer(item.tiktok.username),
          tiktok_followers: numFixer(item.tiktok.followers),
          tiktok_following: numFixer(item.tiktok.following),
          tiktok_average_like: numFixer(item.tiktok.tiktok_average_like),
          tiktok_engagement_rate: numFixer(item.tiktok.tiktok_engagement_rate),
          tiktok_hearts: numFixer(item.tiktok.hearts),
          ...item,
        }
      })
      return { data, totalRecords }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const GetAllUsers = () => {
  const userFilters = useSelector(selectGetAllUserFilters)
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalRecords, setTotalRecords] = useState(0)
  const [initialRecords, setInitialRecords] = useState<User[]>(sortBy({}, "id"))
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [exportCSV, setExportCSV] = useState("")
  const [category, setCategory] = useState("")
  const [selectedRecords, setSelectedRecords] = useState<any>([])
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState({
    modal: false,
    _id: "",
  })
  const [subCategory, setSubCategory] = useState([""])
  const [isEditSubCategoryModalOpen, setIsEditSubCategoryModalOpen] = useState({
    modal: false,
    _id: "",
  })
  const [selectedUser, setSelectedUser] = useState(null)
  const [AISearch, setAISearch] = useState("")
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [queryForUser, setQueryForUser] = useState<any>(null)
  const [changePage, setChangePage] = useState(0)

  const handleFetchData = async () => {
    setLoading(true)
    if (AISearch.length > 0) {
      handleAISearch(userFilters)
    } else {
      const flattenFilters = Object.entries(userFilters).reduce((acc, [key, filter]) => {
        const simpleKeys = [
          "gender",
          "verification",
          "warnings",
          "platform",
          "is_spam",
          "unvisible",
          "last_login",
          "frequency",
          "country",
          "city",
          "job",
          "insta_username",
          "tiktok_username",
        ]

        if (simpleKeys.includes(key) && typeof filter === "string") {
          acc[key] = filter
        } else if (filter && typeof filter === "object") {
          const { min, max } = filter as FilterValue
          if (min) {
            acc[`min_${key}`] = min
          }
          if (max) {
            acc[`max_${key}`] = max
          }
        }

        return acc
      }, {} as { [key: string]: string })

      const params = new URLSearchParams(flattenFilters)
      const keywords = (userFilters.keywords as string[]).map(
        (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
      )
      keywords.forEach((keywords) => {
        params.append("keywords", keywords)
      })

      const hobbies = (userFilters.hobbies as string[]).map(
        (hobby) => hobby.charAt(0) + hobby.slice(1),
      )
      hobbies.forEach((hobbies) => {
        params.append("hobbies", hobbies)
      })
      try {
        const data: any = await fetchData(
          page,
          pageSize,
          params,
          sortByColumn,
          sortOrder,
          exportCSV,
        )
        if (data !== undefined) {
          setInitialRecords(data.data)
          setTotalRecords(data.totalRecords)
        } else {
          setError("No data found")
        }
        setLoading(false)
      } catch (error) {
        setError("No data found")
      }
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder, exportCSV])

  const renderUserId = (index: any) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const removeVerification = async (id: any) => {
    try {
      const response = await TAremoveVerification(id)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, verification: false } : user)),
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const addVerification = async (id: any) => {
    try {
      const response = await TAverifyUser(id, "true")
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, verification: true } : user)),
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const banUser = async (id: any) => {
    try {
      const response = await TAbanUser(id)
      if (response) {
        setInitialRecords((prevUsers) => prevUsers.filter((user) => user._id !== id))
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleEditCategory = async (id: any, category: string) => {
    try {
      const response = await TAupdateCategory(id, category)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, category } : user)),
        )
        setCategory("")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleEditSubCategory = async (id: any, subCategory: string[]) => {
    try {
      const response = await TAupdateSubCategory(id, subCategory)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) =>
            user._id === id ? { ...user, sub_category: subCategory } : user,
          ),
        )
        setSubCategory([""])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = initialRecords.map((record) => record._id)
      setSelectedRecords(allIds)
    } else {
      setSelectedRecords([])
    }
  }

  const handleSelectRow = (id) => {
    setSelectedRecords((prevSelected: any) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }

  const isAllSelected = selectedRecords.length === initialRecords.length
  const isIndeterminate =
    selectedRecords.length > 0 && selectedRecords.length < initialRecords.length

  const handleBulkDelete = async () => {
    try {
      const response = await TAbanManyUsers(selectedRecords)
      if (response) {
        const updatedRecords = initialRecords.filter(
          (record) => !selectedRecords.includes(record._id),
        )
        setInitialRecords(updatedRecords)
        setSelectedRecords([])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleAISearch = async (userFilters: any) => {
    setLoading(true)
    try {
      const response = await TAChatGptSearchUser(AISearch)
      if (response !== undefined) {
        setQueryForUser(response)
        await handleUserWithQuery(response, userFilters)
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  const isValidRange = (range: { min?: string; max?: string }) => {
    const min = range?.min ? Number(range.min) : undefined
    const max = range?.max ? Number(range.max) : undefined

    return (min !== undefined && !isNaN(min)) || (max !== undefined && !isNaN(max))
  }

  const cleanFilters = (filters: Record<string, any>) => {
    return Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => {
        if (value === null || value === "" || (Array.isArray(value) && value.length === 0)) {
          return false
        }

        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          if ("min" in value || "max" in value) {
            return isValidRange(value)
          }
          const cleanedInnerObject = cleanFilters(value)
          return Object.keys(cleanedInnerObject).length > 0
        }

        return true
      }),
    )
  }
  const handleUserWithQuery = async (queryRes: any, userFilters: any) => {
    setLoading(true)

    try {
      const cleanFilterData = cleanFilters(userFilters)
      const query = {
        ...(queryRes || queryForUser),
        isVerification: true,
        ...cleanFilterData,
        ...(cleanFilterData.user_category?.value && {
          user_category: cleanFilterData.user_category.value,
        }),
      }

      const response = await TAAISearchUser(query, page, pageSize, sortByColumn, sortOrder)
      if (response && Array.isArray(response.users)) {
        const totalPages = response.totalPages || 0
        const data = response.users.map((item: any, index: any) => ({
          id: index + 1,
          ...item,
        }))

        setInitialRecords(data)
        setTotalRecords(totalPages * pageSize)
      } else {
        setError("No data found.")
      }
    } catch (error: any) {
      console.error("Error fetching users:", error)
      setError(error.message || "An unexpected error occurred.")
    } finally {
      setLoading(false)
    }
  }

  const handlePrioritize = async (id: string, priotory: boolean) => {
    try {
      const response = await TAprioritizeUser(id, priotory)
      if (response) {
        setInitialRecords((prevUsers) =>
          prevUsers.map((user) => (user._id === id ? { ...user, prioritized: priotory } : user)),
        )
        setSubCategory([""])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const columns = columnConfig({
    isAllSelected,
    isIndeterminate,
    handleSelectAll,
    handleSelectRow,
    selectedRecords,
    removeVerification,
    setSelectedUser,
    setIsOpenDetailModal,
    renderUserId,
    addVerification,
    banUser,
    setIsEditCategoryModalOpen,
    setIsEditSubCategoryModalOpen,
    handlePrioritize,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      <Filters
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        handleFetchData={handleFetchData}
      />
      <div className="flex items-center">
        <div
          className="cursor-pointer inline-flex items-center justify-center p-2 mb-4 border border-transparent text-sm font-medium rounded-3xl text-white bg-gray-400 hover:bg-gray-500"
          onClick={() => handleBulkDelete()}
        >
          <span>Seçilenleri Banla</span>
          <FontAwesomeIcon className="ml-2" icon={faBan} style={{ color: "red" }} />
        </div>
        <div
          className="flex w-1/10 bg-gray-100 ml-4 items-center border border-gray-300 rounded-3xl mb-4 p-2 cursor-pointer"
          onClick={() => setIsFilterOpen(true)}
        >
          Filtrele
        </div>
        <div className="flex w-1/3 ml-4 items-center border border-gray-300 rounded-3xl mb-4 bg-gray-100 p-0">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 ml-2" />
          <input
            type="text"
            placeholder="AI araması yap"
            className="w-full h-8 border-none bg-gray-100 focus:outline-none rounded-3xl pl-3"
            aria-label="AI search"
            value={AISearch}
            onChange={(e) => setAISearch(e.target.value)}
          />
        </div>
        <div className="flex flex-row ml-auto text-center mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 p-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-blue-600 hover:bg-blue-700"
            onClick={() => handleFetchData()}
          >
            Kullanıcı Ara
          </button>
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-blue-600 hover:bg-blue-700"
            onClick={() => setExportCSV("true")}
          >
            10 Sayfa Kullanıcı Ara
          </button>
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-green-600 hover:bg-green-700"
            userData={initialRecords}
          />
        </div>
      </div>
      {isEditCategoryModalOpen.modal && (
        <EditCategoryModal
          category={category}
          setCategory={setCategory}
          onClose={() => {
            setIsEditCategoryModalOpen({ modal: false, _id: "" })
            setCategory("")
          }}
          handleSave={() => handleEditCategory(isEditCategoryModalOpen._id, category)}
        />
      )}
      {isEditSubCategoryModalOpen.modal && (
        <EditSubCategoryModal
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          onClose={() => {
            setIsEditSubCategoryModalOpen({ modal: false, _id: "" })
            setSubCategory([""])
          }}
          handleSave={() => handleEditSubCategory(isEditSubCategoryModalOpen._id, subCategory)}
        />
      )}
      <ProfileDetailModal
        data={selectedUser}
        isOpen={isOpenDetailModal}
        onClose={() => {
          setIsOpenDetailModal(false)
        }}
      />
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <DataTable
              highlightOnHover
              className="whitespace-nowrap table-hover"
              records={initialRecords}
              columns={columns}
              totalRecords={totalRecords}
              recordsPerPage={pageSize}
              page={page}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={(e) =>
                handleSorting(e.columnAccessor, setSortByColumn, setSortOrder)
              }
              minHeight={200}
              paginationText={({ from, to, totalRecords }) =>
                `Showing  ${from} to ${to} of ${totalRecords} entries`
              }
            />
            <div className="flex justify-end items-center gap-3 mt-4 mr-10">
              <input
                type="number"
                placeholder="Sayfa No"
                value={changePage}
                className="w-20 px-2 py-2 text-center border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                onChange={(e) => setChangePage(e.target.valueAsNumber)}
                min={1}
                max={Math.ceil(totalRecords / pageSize)}
              />

              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition"
                onClick={() => setPage(changePage)}
              >
                Atla
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GetAllUsers
