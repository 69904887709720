import React from "react"
import Modal from "../../../../components/Modal"

interface ModalFieldProps {
  modalOpen: boolean
  onClose: () => void
  app: any
  handleAssignUser: any
  user_id: string
  setUserId: any
  setAssignUserModal: any
}

const AssingUser: React.FC<ModalFieldProps> = ({
  modalOpen,
  onClose,
  app,
  handleAssignUser,
  user_id,
  setUserId,
  setAssignUserModal,
}) => {
  return modalOpen ? (
    <div className="flex h-full items-center justify-center absolute">
      <Modal
        onClick={onClose}
        classname="fixed inset-0 bg-opacity-50 p-3 flex items-center justify-center z-50"
        classname2="relative bg-white rounded-3xl w-auto"
      >
        <div>
          <div className="bg-white p-8 rounded-lg shadow-md overflow-y-auto h-1/3 max-w-md w-full space-y-4">
            <h2 className="text-xl font-semibold mb-4">Kullanıcı ata</h2>
            <form
              onSubmit={(e) => {
                handleAssignUser(app._id, user_id)
              }}
              className="space-y-4 flex-col"
            >
              <input
                type="text"
                placeholder="Kullanıcı ID giriniz"
                className="block w-full p-2 border rounded"
                onChange={(e) => setUserId(e.target.value)}
              />
              <div className="w-full flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
                  type="submit"
                >
                  Atama Yap
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded ml-4"
                  onClick={() => setAssignUserModal(false)}
                >
                  Kapat
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}

export default AssingUser
