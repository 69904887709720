import {
  faEye,
  faHeartPulse,
  faBan,
  faSave,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"

export const columnConfig = ({
  handleToggleVisibility,
  loading,
  setSortByColumn,
  handleSpamCampaign,
  editMode,
  handleDeleteNote,
  setNotes,
  notes,
  handleUpdateNote,
  toggleEditMode,
  setCampaignNotes,
}) => [
  {
    accessor: "_id",
    title: "Kampanya",
    sortable: false,
    render: ({ _id }: any) => (
      <Link to={`/campaigns/find/${_id}`}>
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </Link>
    ),
  },

  {
    accessor: "brand",
    title: "Marka",
    sortable: false,
    render: ({ brand }: any) => (
      <Link to={`/brands/find/${brand}`}>
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </Link>
    ),
  },
  {
    accessor: "name",
    title: "İsim",
    sortable: true,
    render: ({ name }) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>{name}</div>
    ),
  },
  { accessor: "platform", title: "Platform", sortable: true },
  {
    accessor: "application_counts.invite_new",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.invite_new")}
      >
        Davet Edilen Profiller
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.invite_new || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.invite_success",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.invite_success")}
      >
        Daveti Kabul Eden Profiller
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.invite_success || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.first_application",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.first_application")}
      >
        {" "}
        Başvuran Profiller
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.first_application || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.waiting_content",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.waiting_content")}
      >
        Onaylanan Profiller
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.waiting_content || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.content_to_share",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.content_to_share")}
      >
        Onay Bekleyen İçerikler
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.content_to_share || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.content_approved",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.content_approved")}
      >
        Onaylanan İçerikler
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.content_approved || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.content_shared",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.content_shared")}
      >
        Paylaşılan İçerikler
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.content_shared || 0}</p>
      </div>
    ),
  },
  {
    accessor: "application_counts.application_done",
    title: (
      <div
        className="w-24 whitespace-normal text-center break-words"
        onClick={() => setSortByColumn("application_counts.application_done")}
      >
        Tamamlanan İşbirlikleri
      </div>
    ),
    sortable: true,
    render: ({ application_counts }) => (
      <div className="flex">
        <p className="ml-2">{application_counts.application_done || 0}</p>
      </div>
    ),
  },
  {
    accessor: "visibility",
    title: "Görünürlük",
    sortable: true,
    render: ({ _id, visibility }) => (
      <div className="flex">
        <FontAwesomeIcon
          icon={faHeartPulse}
          size="lg"
          style={{ color: visibility ? "#009e1a" : "#ff0000" }}
        />
        <p className="ml-2">{visibility ? "Aktif" : "Görünür Değil"}</p>
      </div>
    ),
  },
  {
    accessor: "toggle_visibility",
    title: "Görünürlüğü Değiştir",
    sortable: true,
    render: ({ _id, visibility }) => {
      return (
        <div className="flex">
          <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
            <button
              onClick={() => handleToggleVisibility(_id, visibility ? "false" : "true")}
              className="bg-blue-500 text-white rounded-md px-3 py-2 w-full hover:bg-blue-600"
            >
              {loading ? (
                <span className="spinner"></span>
              ) : visibility ? (
                "Yayından Kaldır"
              ) : (
                "Aktifleştir"
              )}
            </button>
          </div>
        </div>
      )
    },
  },
  {
    accessor: "brand_balance",
    title: <div onClick={() => setSortByColumn("brand_balance")}>Bakiye</div>,
    sortable: true,
    render: ({ brand_balance }: any) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {brand_balance || 0}
      </div>
    ),
  },
  {
    accessor: "created_at",
    title: <div onClick={() => setSortByColumn("created_at")}>Oluşturulma Tarihi</div>,
    sortable: true,
    render: ({ created_at }: any) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {new Date(created_at).toLocaleDateString()}
      </div>
    ),
  },
  {
    accessor: "notes",
    title: "Notlar",
    render: ({ _id, notes: initialNote }: { _id: string; notes: string[] }) => (
      <div className="text-center items-center mr-4">
        {editMode[_id] ? (
          <div>
            <ul>
              {initialNote
                .filter((note) => note !== "")
                .map((note, index) => (
                  <li key={index} className="flex items-center">
                    <p className="text-gray-600 mt-2">{note}</p>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mt-2"
                      style={{ color: "#005eff", marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => handleDeleteNote(_id, index)}
                    />
                  </li>
                ))}
            </ul>
            <input
              type="text"
              onChange={(e) => setNotes(e.target.value)}
              className="text-input mt-2"
            />
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "green", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleUpdateNote(_id, [...initialNote, notes])}
            />
          </div>
        ) : (
          <>
            <span>{initialNote}</span>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: "#005eff", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => {
                toggleEditMode(_id, initialNote)
                setCampaignNotes(initialNote)
              }}
            />
          </>
        )}
      </div>
    ),
  },
  {
    accessor: "is_spam",
    title: "Spam Durumu",
    render: ({ _id }: any) => (
      <div>
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "green", cursor: "pointer", marginLeft: "10px" }}
          onClick={() => handleSpamCampaign(_id)}
        />
      </div>
    ),
  },
  {
    accessor: "max_cost",
    title: <div onClick={() => setSortByColumn("max_cost")}>Max Tutar</div>,
    sortable: true,
    render: ({ max_cost }: any) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {max_cost && max_cost.toLocaleString()}
      </div>
    ),
  },
]
