import React from "react"
import Modal from "./Modal"

interface ModalFieldProps {
  modalOpen: boolean
  onClose: () => void
  data: any
  platform: string | undefined
  modalType: string
  tabType?: string
}

const ModalField: React.FC<ModalFieldProps> = ({
  modalOpen,
  onClose,
  data,
  platform,
  modalType,
  tabType,
}) => {
  const parsedData =
    tabType === "contents"
      ? (() => {
          try {
            return JSON.parse(
              data.state_history.find(
                (item: any) => item.state === "content_to_share" || item.state === "ugc_content",
              )?.state_payload,
            )
          } catch (error) {
            console.error("Failed to parse state_payload:", error)
            return null
          }
        })()
      : data?.state_payload === ""
      ? (() => {
          try {
            return JSON.parse(data.state_history[0].state_payload)
          } catch (error) {
            console.error("Failed to parse state_payload:", error)
            return null
          }
        })()
      : (() => {
          try {
            return JSON.parse(data?.state_payload)
          } catch (error) {
            console.error("Failed to parse state_payload:", error)
            return null
          }
        })()

  return modalOpen ? (
    <div className="flex h-full items-center justify-center absolute">
      <Modal
        onClick={onClose}
        classname="fixed inset-0 bg-gray-800 bg-opacity-50 p-3 flex items-center justify-center z-50"
        classname2="relative bg-white rounded-3xl w-auto"
      >
        <div className="flex flex-col items-center justify-center w-full h-full p-8">
          <div className="w-full">
            <div className="flex flex-row w-full justify-between items-center mb-4">
              <h2 onClick={() => console.log(parsedData)} className="text-lg font-bold text-center">
                {modalType === "stats" ? "İstatistikler" : "Önizleme"}
              </h2>
              <button
                className="w-6 h-6 p-1 cursor-pointer bg-gray-500 bg-opacity-10 rounded-lg flex items-center justify-center text-xl font-bold"
                onClick={onClose}
              >
                ×
              </button>
            </div>
            <div className="flex flex-col items-center justify-center w-full h-full">
              {platform === "insta-reels" &&
                (parsedData?.img ? (
                  <div className="w-full h-auto">
                    <img
                      className="w-[100%] h-[600px] object-contain"
                      src={parsedData.img}
                      alt="Preview"
                    />
                    <div className="w-full justify-center flex gap-2 mt-2">
                      {modalType === "stats" && (
                        <div className="flex gap-2">
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Beğeni Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.reelslikes}
                            </div>
                          </div>
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Yorum Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.reelscomments}
                            </div>
                          </div>
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">İzlenme Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.view}
                            </div>
                          </div>
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Paylaşım Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.reelssharings}
                            </div>
                          </div>
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Kaydetme Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.reelssavings}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <p>No Image</p>
                ))}
              {platform === "insta-story" &&
                (parsedData?.img ? (
                  <div className="w-full h-auto">
                    <img
                      className="w-[100%] h-[600px] object-contain"
                      src={parsedData.img}
                      alt="Preview"
                    />
                    <div className="w-full justify-center flex gap-2 mt-2">
                      {modalType === "stats" && (
                        <div className="flex gap-2">
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">İzlenme Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.view}
                            </div>
                          </div>
                          {/*    <div className="flex gap-2 p-1">
                            <MdModeComment color="gray" size={20} />
                            <div className="text-gray-600 font-bold">{parsedData?.reelscomments}</div>
                          </div>
                          <div className="flex gap-2 p-1">
                            <IoEyeSharp color="gray" size={20} />
                            <div className="text-gray-600 font-bold">{parsedData?.view}</div>
                          </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <p>No Image</p>
                ))}
              {platform === "insta-post" &&
                (parsedData?.img ? (
                  <div className="w-full h-auto">
                    <img
                      className="w-[100%] h-[600px] object-contain"
                      src={parsedData.img}
                      alt="Preview"
                    />
                    <div className="w-full justify-center flex gap-2 mt-2">
                      {modalType === "stats" && (
                        <div className="flex w-full justify-center items-center gap-2">
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Beğeni Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.like_count}
                            </div>
                          </div>
                          <div className="flex flex row items-center gap-1 p-1">
                            <p className="text-sm font-poppins-medium">Yorum Sayısı:</p>
                            <div className="text-gray-600 text-sm font-poppins-medium">
                              {parsedData?.comment_count}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <p>No Image</p>
                ))}
              {platform?.includes("tiktok") && (
                <div className="w-full h-auto">
                  <video
                    src={parsedData?.img}
                    muted
                    loop
                    className="w-[100%] h-72 object-contain shrink-0"
                    controls={true}
                  />
                  <div className="w-full justify-center items-center flex gap-2 mt-2">
                    <div className="flex flex row items-center gap-1 p-1">
                      <p className="text-sm font-poppins-medium">Beğeni Sayısı:</p>
                      <div className="text-gray-600 text-sm font-poppins-medium">
                        {parsedData?.like_count}
                      </div>
                    </div>
                    <div className="flex flex row items-center gap-1 p-1">
                      <p className="text-sm font-poppins-medium">Yorum Sayısı:</p>
                      <div className="text-gray-600 text-sm font-poppins-medium">
                        {parsedData?.comment_count}
                      </div>
                    </div>
                    <div className="flex flex row items-center gap-1 p-1">
                      <p className="text-sm font-poppins-medium">Paylaşım Sayısı:</p>
                      <div className="text-gray-600 text-sm font-poppins-medium">
                        {parsedData?.share_count}
                      </div>
                    </div>
                    <div className="flex flex row items-center gap-1 p-1">
                      <p className="text-sm font-poppins-medium">İzlenme Sayısı:</p>
                      <div className="text-gray-600 text-sm font-poppins-medium">
                        {parsedData?.play_count}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*platform === "insta-story" */}
            </div>
          </div>
          {platform?.includes("insta-story") && modalType !== "stats" && (
            <div
              className="w-full text-center text-[#FF006A] cursor-pointer mt-3"
              onClick={() => window.open(`https://www.instagram.com/${data?.insta_username}`)}
            >
              Instagrama Git
            </div>
          )}
        </div>
      </Modal>
    </div>
  ) : null
}

export default ModalField
