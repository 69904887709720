import React from "react"
import Modal from "../../../../components/Modal"

interface ModalFieldProps {
  modalOpen: boolean
  onClose: () => void
  newPricingUser: number
  setNewPricingUser: any
  newPricingBrand: number
  setNewPricingBrand: any
  handleSavePricingData: any
  handleClosePricingModal: any
  application: any
}

const EditUserPriceBrand: React.FC<ModalFieldProps> = ({
  modalOpen,
  onClose,
  newPricingUser,
  setNewPricingUser,
  newPricingBrand,
  setNewPricingBrand,
  handleSavePricingData,
  handleClosePricingModal,
  application,
}) => {
  return modalOpen ? (
    <div className="flex h-full items-center justify-center absolute">
      <Modal
        onClick={onClose}
        classname="fixed inset-0 bg-gray-600 bg-opacity-50 p-3 flex items-center justify-center z-50"
        classname2="relative bg-white rounded-3xl w-auto"
      >
        <div>
          <div className="bg-white p-8 rounded-lg bg-opacity-50 overflow-y-auto h-1/3 max-w-md w-full space-y-4">
            <h2 className="text-xl font-semibold mb-4">Tutar Düzenlemesi</h2>

            <div className="space-y-4 flex flex-col">
              <label htmlFor="newPricingUser">Yeni Kullanıcı Tutarı</label>
              <input
                type="number"
                value={newPricingUser}
                onChange={(e) => setNewPricingUser(parseInt(e.target.value))}
                placeholder="Enter new pricing user"
                className="block w-full p-2 border rounded"
              />

              <label htmlFor="newPricingBrand">Yeni Marka Tutarı</label>
              <input
                type="number"
                value={newPricingBrand}
                onChange={(e) => setNewPricingBrand(parseInt(e.target.value))}
                placeholder="Enter new pricing brand"
                className="block w-full p-2 border rounded"
              />

              <div className="w-full flex justify-end">
                <button
                  className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
                  onClick={() => handleSavePricingData(application)}
                >
                  Kaydet
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded ml-4"
                  onClick={handleClosePricingModal}
                >
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}

export default EditUserPriceBrand
